body {
  font-family: 'Asap', sans-serif;
  box-sizing: border-box;
}

p {
  margin: 0;
}

select {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.github {
  position: absolute;
  display: flex;
  bottom: 18px;
  right: 24px;
  align-items: center;
}

.github > a {
  top: 12px;
  margin-left: 8px;
}

.center-horizontal {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

header > h1 {
  text-align: center;
  margin-bottom: 0;
}

header > p {
  text-align: center;
  color: #888;
}

.center {
  position: absolute;
  top: 60%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
}

.center > * {
  margin: 0 12px;
}

.plus {
  font-size: 42px;
}

.dropArea {
  display: flex;
  width: 140px;
  height: 140px;
  border: 2px dashed black;
  border-radius: 20px;
  justify-content: center;
  align-items: center;
  text-align: center;
  transition: all ease-in-out 120ms;
  cursor: pointer;
  background-color: #eee;
}

.dropArea.selected {
  color: #2ecc71;
  border-color: #2ecc71;
}

.dropArea * {
  cursor: pointer;
}

.dropArea p {
  margin-top: 6px;
}

.dropArea:hover {
  border-color: #3498db;
  color: #3498db;
  transform: scale(1.1);
}

input[type='file'] {
  display: none;
}

#loadingIndicator {
  position: absolute;
  bottom: 24px;
  left: 50%;
  transform: translateX(-50%);
}
